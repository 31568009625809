/* eslint-disable */
/*
  This polyfil is probably incomplete, I made this just to use localforage on internet explorer.
  This polyfill is tested only with this library and works well, but I don't know if it will work i other cases.
  This polyfill is based on this code: https://github.com/nolanlawson/blob-util/blob/4ae6c7a14d3240d977bbdab9ae2f0bb8905ecc37/lib/index.js#L123-L141
*/

if (window.FileReader) {
  if (typeof FileReader.prototype.readAsBinaryString !== 'function') {
    console.log('Warning: Using readAsBinaryString polyfill');
    FileReader.prototype.readAsBinaryString = function(blb) {
      var reader = new FileReader();
      var that = this;
      var conversor = function(e) {
        var toConvert = e.target.result || '';
        var binary = '';
        var bytes = new Uint8Array(toConvert);
        var length = bytes.byteLength;
        var i = -1;
        while (++i < length) {
          binary += String.fromCharCode(bytes[i]);
        }
        var f = {};
        for (var property in e) {
          if (property != 'target') {
            f[property] = e[property];
          }
        }
        f.target = {};
        for (var property in e.target) {
          if (property != 'result') {
            f.target[property] = e.target[property];
          }
        }
        f.target.result = binary;
        that.onload(f);
      };
      if (!(this.onloadend === undefined || this.onloadend === null)) {
        reader.onloadend = conversor;
      }
      if (!(this.onerror === undefined || this.onerror === null)) {
        reader.onerror = this.onerror;
      }
      if (!(this.onabort === undefined || this.onabort === null)) {
        reader.onabort = this.onabort;
      }
      if (!(this.onloadstart === undefined || this.onloadstart === null)) {
        reader.onloadstart = this.onloadstart;
      }
      if (!(this.onprogress === undefined || this.onprogress === null)) {
        reader.onprogress = this.onprogress;
      }
      if (!(this.onload === undefined || this.onload === null)) {
        reader.onload = conversor;
      }
      //abort not implemented !!!
      reader.readAsArrayBuffer(blb);
    };
  }
}
