import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Needed for ie 11
import 'utils/readAsBinaryStringPolyfill';

import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';

import {
  ConfigFetcher,
  Loading,
  DefaultToast,
} from '@scoutbeedev/react-components';

const Index = React.lazy(() => import('./indexDummy'));

ReactDOM.render(
  <ConfigFetcher env={process.env}>
    <Suspense fallback={<Loading loading />}>
      <DefaultToast>
        <Index />
      </DefaultToast>
    </Suspense>
  </ConfigFetcher>,
  document.getElementById('root'),
);
